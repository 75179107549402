import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Person from "./Person"
import { StaticImage } from "gatsby-plugin-image"
import FeaturedImage from "./common/FeaturedImage"
export default function OmOss() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 16 }) {
        id
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.6)
              }
            }
          }
        }
        hyresgaster {
          person {
            sysselsattning
            namn
            hemsida
            bio
          }
        }
      }
    }
  `)
  const { content, hyresgaster } = data.wpPage
  const { person } = hyresgaster
  const persons = person
  const { featuredImage } = data.wpPage

  return (
    <div className="">
      <div id="om-oss" className="grid gap-8 md:gap-16">
        <div className="flex flex-col gap-4 md:gap-8">
          {" "}
          <div className="">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <FeaturedImage image={featuredImage} />
        </div>
        <div className="grid gap-4 md:gap-8">
          <h2 className="">Vi som jobbar här</h2>
          <div className="grid md:grid-cols-2 items-start gap-2 md:gap-4">
            {persons.sort().map((person, i) => {
              return <Person key={i} person={person} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
