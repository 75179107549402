import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FeaturedImage from "../components/common/FeaturedImage"
import OmOss from "../components/OmOss"

export default function OmossPage() {
  return (
    <Layout>
      <SEO title="Om oss" />
      <div className="flex flex-col md:flex-row gap-4 md:gap-8 w-full">
        <div>
          <OmOss />
        </div>
      </div>
    </Layout>
  )
}
