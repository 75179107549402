import React from "react"
import Link from "./common/Link"

export default function Person({ person }) {
  const { sysselsattning, namn, hemsida, bio } = person
  function removeHttp(url) {
    return url.replace(/^https?:\/\//, "")
  }

  return (
    <div className="flex flex-col gap-4 ">
      <div>
        <h4 className=" font-semibold ">
          <span>{namn}</span>
          <span className="font-normal">
            {sysselsattning && ", " + sysselsattning}
          </span>
        </h4>
        <div className=" font-sans"></div>
        <div className="">
          {hemsida && (
            <Link className=" hover:underline" to={hemsida}>
              {removeHttp(hemsida)}
            </Link>
          )}
        </div>
      </div>

      {/* <div className="text-gray-600">
        <div dangerouslySetInnerHTML={{ __html: bio }} />
      </div> */}
    </div>
  )
}
